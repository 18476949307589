<template>
  <div>
    <h4>
      {{ title }}
      <span v-if="diagnosis">
        {{ customerName }}
      </span>
    </h4>
    <p>
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    diagnosis: Object,
    title: String,
    customerName: String,
    description: String
  }
};
</script>

<style lang="scss" scoped>
p {
  color: #74788d;
  font-size: 13px;
  font-family: 'poppins';
}
span {
  color: var(--primary);
}
</style>
