<template>
  <div>
    <b-button variant="primary" style="" @click="onClick">
      {{ text }}
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    onClick: Function,
    text: String
  }
};
</script>

<style lang="scss" scoped>
button {
  width: 15rem;
  padding: 10px;
  margin: 10px auto;
}
</style>
