<template>
  <div>
    <b-modal
      v-model="show"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label-for="comment-input">
          <b-form-textarea
            id="comment-textarea"
            v-model="comment"
            rows="5"
            placeholder="Texto com o comentário do corretor…"
          />
        </b-form-group>
      </form>

      <template #modal-header>
        <!-- Emulate built in modal header close button action -->
        <div style="margin: 0 auto">
          <h5 style="color: var(--grey)">
            {{ item && item.comentario ? 'Editar' : 'Adicione o seu' }}
            comentário
          </h5>
        </div>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="md" variant="danger" @click="cancel()">
          Cancelar
        </b-button>
        <b-button size="md" variant="success" @click="ok()">
          {{ isCard ? 'OK' : 'Enviar para o especialista' }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { updateDiagnosisProductItemComentario } from '@/services/requests/diagnosis';

export default {
  props: {
    isCard: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      comment: ''
    };
  },
  methods: {
    resetModal() {
      this.comment = '';
      this.$store.state.modal.isOpenModalEditComment = false;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      const item = JSON.parse(JSON.stringify(this.item));
      item.comentario = this.comment;
      updateDiagnosisProductItemComentario(
        item.diagnostico_produto_item_id,
        item
      )
        .then(() => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-prevent-closing');
            this.resetModal();
          });

          this.$emit('onSuccess', this.comment);
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    show: {
      get() {
        return this.$store.state.modal.isOpenModalEditComment;
      },
      set(value) {
        this.$store.state.modal.isOpenModalEditComment = value;
      }
    },
    item() {
      return this.$store.state.modal.itemModalEditComment;
    }
  },
  watch: {
    show(value) {
      if (!value) return;

      if (this.item) {
        this.comment = this.item.comentario;
      }
    }
  }
};
</script>
