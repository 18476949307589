<template>
  <div>
    <b-modal v-model="isOpenModalViewComment" ok-only>
      <template #modal-header>
        <!-- Emulate built in modal header close button action -->
        <div style="margin: 0 auto">
          <h5 style="color: var(--grey)">
            {{ title }}
          </h5>
        </div>
      </template>

      <template #modal-footer="{ ok }">
        <b-button size="md" variant="success" @click="ok()">OK</b-button>
      </template>

      <div class="p-3" style="border-color: var(--light-grey) !important">
        {{ commentModalViewComment }}
        <div class="text-muted" v-if="!commentModalViewComment">
          Sem comentário
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ModalViewCommentOld',
  props: {
    title: {
      type: String,
      default: 'Comentário'
    }
  },
  computed: {
    ...mapState('modal', ['commentModalViewComment']),
    isOpenModalViewComment: {
      get() {
        return this.$store.state.modal.isOpenModalViewComment;
      },
      set(value) {
        this.$store.state.modal.isOpenModalViewComment = value;
      }
    }
  }
};
</script>
